import React from 'react';
import GlobalStyles from '../components/GlobalStyles';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import PageHeader from '../components/Header';
import BlockSection from '../components/BlockSection';
import Featured from '../components/Featured';
import Footer from '../components/Footer';
import Excerpt from '../components/Excerpt';

interface Edge {
  node: {
    _id: string;
    slug: { current: string };
    title: string;
  };
}

interface AppProps {
  data: {
    allSanityPage: {
      edges: Edge[];
    };
  };
}

const App: React.FC<AppProps> = ({ data }) => {
  const {
    title,
    description,
    headline,
    subHeader,
    excerpt,
    sectionOne,
    sectionTwo,
    cta,
    mainImage,
    secondaryImage,
    brand,
  } = data.sanityHomeSettings;

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <PageHeader
        headline={headline}
        subHeader={subHeader}
        image={mainImage.asset.fluid.src}
        logo={brand.logo.asset.fixed}
        phone={brand.phone}
      />
      <BlockSection id="lawsuit-information" content={sectionOne} />
      <Excerpt excerpt={excerpt} image={secondaryImage.asset.fluid.src} />
      <BlockSection id="general-information" content={sectionTwo} />
      <BlockSection id="call-to-action" content={cta} />
      <Featured
        logoColor="#ffffff"
        featured={brand.featured}
        filterId="white"
        opacity={0.5}
      />
      <Footer brand={brand} />
    </>
  );
};

export default App;

export const pageQuery = graphql`
  query MyQuery {
    sanityHomeSettings {
      id
      title
      description
      headline
      subHeader
      excerpt
      sectionOne {
        _key
        _type
        style
        children {
          _key
          _type
          text
        }
      }
      sectionTwo {
        _key
        _type
        style
        children {
          _key
          _type
          text
        }
      }
      cta {
        _key
        _type
        style
        children {
          _key
          _type
          text
        }
      }
      mainImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      secondaryImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      brand {
        name
        legalName
        tos
        phone
        featured {
          _key
          asset {
            fluid(maxWidth: 150) {
              ...GatsbySanityImageFluid
            }
          }
        }
        logo {
          asset {
            fixed(width: 200) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
  }
`;
