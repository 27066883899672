export default {
  font: `'proxima-nova', sans-serif`,
  colors: {
    primary: '#04263C',
    secondary: '#E13B2F',
    black: '#202020',
  },
  breakpoints: {
    sm: '768px',
    md: '960px',
    lg: '1200px',
  },
};
