import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from './theme';

interface FormData {
  [key: string]: string[] | string;
}

const Form: React.FC = () => {
  const [formStatus, setFormStatus] = useState('PENDING');
  const { register, handleSubmit, errors, watch } = useForm<FormData>();

  const currentFormData = watch();

  const onSubmit = (
    data: FormData,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setFormStatus('SENDING');

    fetch('https://formkeep.com/f/3d79c0b4c49a', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(currentFormData),
    })
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'gaFormSubmit',
          gaEventCategory: 'Forms',
          gaEventAction: 'Submission',
          gaEventLabel: 'Lead',
        });
        setFormStatus('SUCCESS');
      })
      .catch(() => {
        setFormStatus('ERROR');
      });
  };

  return (
    <FormWrap>
      {formStatus === 'SENDING' ? (
        <span className="form-header">We are submitting your request.</span>
      ) : formStatus === 'SUCCESS' ? (
        <>
          <span className="form-header">Thank you!</span>
          <p>A member from our intake team will be reaching out to you soon.</p>
          <p>We will attempt to call you at the number that you provided.</p>
        </>
      ) : (
        <>
          <span className="form-header">Start your free consultation.</span>
          <form className="form-inner" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="firstName" className="input-label">
              First Name*
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              ref={register({ required: true })}
              className={`form-text-input ${errors.firstName && 'field-error'}`}
              placeholder={errors.firstName && 'A first name is required'}
            />
            <label htmlFor="lastName" className="input-label">
              Last Name*
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              ref={register({ required: true })}
              className={`form-text-input ${errors.lastName && 'field-error'}`}
              placeholder={errors.lastName && 'A last name is required'}
            />
            <label htmlFor="email" className="input-label">
              Email
            </label>
            <input
              type="text"
              name="email"
              id="email"
              ref={register}
              className="form-text-input"
            />
            <label htmlFor="phone" className="input-label">
              Phone
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              ref={register({ required: true })}
              className={`form-text-input ${errors.phone && 'field-error'}`}
              placeholder={errors.phone && 'A phone number is required'}
            />
            <label htmlFor="description" className="input-label">
              Case Info
            </label>
            <textarea
              name="description"
              id="description"
              ref={register({ required: true })}
              className={`form-text-input ${
                errors.description && 'field-error'
              }`}
              placeholder={
                errors.description && 'Please tell us more about your case.'
              }
              rows="4"
            />
            <div className="form-button-group">
              <button className="form-button" type="submit">
                Submit
              </button>
            </div>
            {Object.keys(errors).length > 0 && (
              <span className="error-prompt light">
                Please complete all required fields.
              </span>
            )}
            {formStatus === 'ERROR' && (
              <>
                <span className="error-prompt light">
                  ERROR: There was a problem submitting your information. Please
                  try resending the form. If the problem persists, please give
                  us a call.
                </span>
              </>
            )}
          </form>
        </>
      )}
    </FormWrap>
  );
};

export default Form;

const FormWrap = styled.div`
  padding: 1rem;
  width: 100%;
  background: rgba(102, 128, 174, 0.7);
  grid-area: form;
  overflow: hidden;
  color: white;
  margin-bottom: 1rem;
  border-radius: 0.125rem;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    min-width: 250px;
    align-self: center;
  }
  .form-inner {
    display: flex;
    flex-direction: column;
  }

  .form-header {
    font-size: 1.75rem;
    font-weight: 800;
    line-height: 2rem;
    @media screen and (min-width: ${theme.breakpoints.md}) {
      font-size: 2rem;
    }
  }

  .input-label {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 600;
    margin: 0.75rem 0 0.25rem 0;
  }

  .check-label {
    margin-left: 0.25rem;
  }

  .select {
    font-display: swap;
    font-family: ${theme.font};
    color: ${theme.colors.primary};
    font-size: 1.125rem;
    padding: 0.25em;
    border-radius: 0.125em;
  }
  .form-text-input {
    font-display: swap;
    font-family: ${theme.font};
    color: ${theme.colors.primary};
    font-size: 1.125rem;
    border: none;
    border-radius: 0.125em;
    padding: 0.25em;
    transition: all 150ms ease-in-out;
    &:hover {
      background: #e4e4e4;
    }
  }

  .form-button {
    font-display: swap;
    font-family: ${theme.font};
    background: ${theme.colors.secondary};
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5em;
    border-radius: 0.125em;
    margin-top: 1rem;
    transition: all 100ms ease-in-out;
    &:hover {
      background: $secondary-active;
      cursor: pointer;
      transform: scale3d(1.025, 1.025, 1);
    }
    @media screen and (min-width: ${theme.breakpoints.md}) {
      font-size: 1.25rem;
    }
  }

  .form-button-group {
    display: flex;
    :nth-child(1) {
      margin-right: 0.25em;
    }
    :nth-child(2) {
      margin-left: 0.25em;
    }
    button {
      font-display: swap;
      font-family: ${theme.font};
      font-size: 1rem;
      width: 100%;
      margin-top: 1rem;
      @media screen and (min-width: ${theme.breakpoints.md}) {
        font-size: 1.25rem;
      }
    }
  }

  .field-error {
    border: solid ${theme.colors.secondary} 2px;
  }

  .error-prompt {
    margin-top: 1rem;
    line-height: 1rem;
    text-align: center;
  }

  .confirmation-video {
    width: 100%;
    margin-bottom: 0;
  }

  .confirmation-header {
    font-weight: 800;
  }

  .confirmation-list {
    font-size: 0.85rem;
    line-height: 1rem;
  }
`;
